<template>
  <div style="height:100%">
    <!-- 预览图片 -->
    <img v-if="type == 1" :src="imageUrl" alt="Your Image" width="100%" />

    <!-- 预览文本文件 -->
    <object
      v-if="type == 2"
      style="width:100%;height:100%"
      :data="textFileUrl"
      type="text/plain;charset=UTF-8"
    ></object>
    <!-- <iframe style="width:100%;height:100%" v-if="type == 2" :src="textFileUrl"></iframe> -->
    <iframe
      v-if="type == 4"
      :src="pdfUrl"
      type="application/pdf"
      width="100%"
      height="100%"
    />
    <!-- 预览MP4视频文件 -->
    <video
      style="height:100%"
      v-if="type == 5"
      :src="videoUrl"
      controls
    ></video>
    <div v-if="type == 99">
      暂不支持此类附件在线预览。
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: "your-image.jpg", // 图片路径
      textFileUrl: "your-text-file.txt", // 文本文件路径
      videoUrl: "your-video-file.mp4", // MP4视频文件路径
      pdfUrl: "your-pdf-file.pdf",
      type: 0
    };
  },
  mounted() {
    if (this.$route.query.url) {
      let url = this.$route.query.url;
      const fileExtension = url.substring(url.lastIndexOf(".") + 1);
      console.log(fileExtension);
      if (
        fileExtension == "jpg" ||
        fileExtension == "jpeg" ||
        fileExtension == "png" ||
        fileExtension == "webp" ||
        fileExtension == "gif"
      ) {
        this.type = 1;
        this.imageUrl = url;
      } else if (
        fileExtension == "mp4" ||
        fileExtension == "avi" ||
        fileExtension == "mov"
      ) {
        this.type = 5;
        this.videoUrl = url;
      } else if (fileExtension == "txt") {
        this.type = 2;
        this.textFileUrl = url;
      } else if (fileExtension == "pdf") {
        this.type = 4;
        this.pdfUrl = url;
      } else {
        this.type = 99;
      }
    }
  }
};
</script>
